import React, { useMemo } from "react"
import { EngineeringLayout } from "../../../layouts"
import TopComponent from "../../../components/TopComponent/TopComponent"
import ChyronItem from "../../../components/ChyronItem/ChyronItem"
import ZigZag from "../../../components/ZigZag/ZigZag"
import Contact from "../../../components/Contact/Contact"
import "./Engineering1.scss"
import Testimonial from "../../../components/Testimonial/Testimonial"
import TestimonialSlider from "../../../components/TestimonialSlider/TestimonialSlider"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../../constants"
import { useZigzagImageSides } from "../../../hooks/useZigzagImageSides"
import SEO from "../../../components/Seo"
import { localizeDataNodes, localizeStaticNodes } from "../../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"


const ZIGZAG_IMAGE_SIDE_START = "left"

const Engineering1 = ({ pageContext: { pageData, lang, testimonials, clients, companies }, data, location }) => {
  const {
    allDzemoApiZigzags,
  } = useMemo(() => localizeDataNodes(data, lang), [data, lang])

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])
  const localizedZigzags = useMemo(() => localizeStaticNodes(pageData[lang].zigzags, lang), [pageData, lang])

  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )

  return (
    <EngineeringLayout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="engineering-page-1">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent
              data={localizedTops}
              imageFormatName={"normal"}
              wave
            />
          )}
        </section>

        <section className="chyron-container">
          <TestimonialSlider chyron={true}>
            {clients.map(client => {
              const { id, logo, webAddress, staticLogo } = client.node[lang]
              const { alternativeText } = logo
              const { publicUrl } = staticLogo

              return (
                <ChyronItem
                  key={id}
                  data={{
                    src: `${publicUrl}`,
                    alt: alternativeText,
                    url: webAddress,
                  }}
                />
              )
            })}
          </TestimonialSlider>
        </section>

        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          {localizedZigzags.length > 0 &&
            localizedZigzags.map((zigzag, index) => {
              return (
                <ZigZag
                  key={zigzag.id}
                  data={{
                    ...zigzag,
                    image: zigzag.image[0],
                    sideOfImage: zigzagImageSides[index],
                  }}
                />
              )
            })}
        </section>

        <section
          style={{
            background: "rgba(241, 241, 241, 1)",
            minHeight: "717px",
          }}
        >
          <TestimonialSlider>
            {testimonials.map(testimonial => {
              const { id } = testimonial.node[lang]
              return <Testimonial key={id} data={testimonial.node[lang]} />
            })}
          </TestimonialSlider>
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </EngineeringLayout>
  )
}

export default Engineering1

export const query = graphql`
  query engineering1PageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiClients {
      nodes {
        en {
          id
          webAddress
          logo {
            caption
            alternativeText
            id
            name
            size
            url
            width
            height
          }
        }
        de {
          id
          webAddress
          logo {
            caption
            alternativeText
            id
            name
            size
            url
            width
            height
          }
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { page: { id: { eq: $pageId } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }

    allDzemoApiTestimonials(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
        de {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
      }
    }
  }
`
